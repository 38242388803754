<template>
    <div id="courseTeaching">
        
        <div class="i-wrap">
            <div class="banner-c">
                <div class="swiper-container">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide" 
                           v-for="(item, i) in bList"
                           :key="i">
                        <img style="cursor:pointer" :src="`${baseUrl}/${item.file}`" alt="" @click="GObannerItem(item)"/>
                      </div>
                    </div>
                    <div class="swiper-pagination"></div>
                </div>
            </div>
            <!-- <div style="width: 100%;height: 300px;display: flex;justify-content: space-between;">
                <div style="width: 24%;height: 300px;background: #fff;">
                    <div id="main1" style="width: 100%;height: 100%;"></div>
                </div>
                <div style="width: 24%;height: 300px;background: #fff;">
                    <div id="main2" style="width: 100%;height: 100%;"></div>
                </div>
                <div style="width: 24%;height: 300px;background: #fff;">
                    <div id="main3" style="width: 100%;height: 100%;"></div>
                </div>
                <div style="width: 24%;height: 300px;background: #fff;">
                    <div id="main4" style="width: 100%;height: 100%;"></div>
                </div>
            </div> -->
            <!-- 搜索 -->
            <div class="inputIcon">
                <el-input
                style="margin-top: 10px;"
                placeholder=""
                v-model="searchValue">
                </el-input>
                <img src="@/assets/img/courseTechingimg/inputSearch.png" @click="searchClick"/>
            </div>
            <div class="screen">
                <div style="display: flex;align-items: center;height: 100%;flex-wrap: wrap;align-content: flex-start;">
                    <span :class="{active:screenItemIndex == -1}" class="screenClass" @click="quanbu()"> 全部 </span>
                     <div :class="{active:screenItemIndex == index}" class="screenClass" v-for="(item,index) in screenList" :key="index" @click="screenName(index,item.id)">
                        <span>{{ item.stageName }}</span>
                     </div>
                </div>
            </div>

            <ul 
                v-infinite-scroll="load" 
                style="overflow-y: auto;height: 800px;list-style: none;margin-top:10px;"
                infinite-scroll-immediate="false"
                class="ulList"
                >
                <li class="dataListRow" v-for="(item,index) in teachingCoursesList" :key="index" @click="rowClick(item.id)">
                    <div style="margin: 0.8%;">
                        <img style="height: 100%;width:240px;object-fit: scale-down;" :src="`${baseUrl}/${item.coverPic}`" alt="" srcset="">
                    </div>
                    
                    <div style="float: left;margin: 0.8%;width: 80%;">
                        <p style="font-size: 1.3rem;font-weight: bold;padding-bottom: 5px;">{{ item.trainingName }}</p>
                        <p style="padding-bottom: 5px;" ><span style="font-size: 14px;color: #666666;">实训发布时间: </span><span style="font-size: 14px;">{{ item.insertTime }}</span></p>
                        <p style="padding-bottom: 5px;"><span style="font-size: 14px;color: #666666;">版权所有人: </span><span style="font-size: 14px;">{{ item.producer }}</span></p>
                        <p style="padding-bottom: 5px;"><span style="font-size: 14px;color: #666666;">适用专业: </span><span style="font-size: 14px;">{{ item.majorName }}</span></p>
                        <p style="padding-bottom: 5px;"><span style="font-size: 14px;color: #666666;">适用行业: </span><span style="font-size: 14px;">{{ item.industryName }}</span></p>
                        <p style="text-align: end;font-size: 14px;"><span style="font-size: 14px;color: #666666;">课时:</span><span style="padding-right: 10px;">{{item.dtKnowledgePointCount}}</span><span style="font-size: 14px;color: #666666;">学习人数:</span><span>{{item.trainingCount}}</span></p>
                    </div>
                </li>
                <!-- <div style="display: flex;justify-content: center;width: 100%;height: 40px;align-items: center;">
                    <el-pagination small background  v-model:current-page="currentPage4"  layout="total,prev, pager, next,jumper" :total="100" />
                </div> -->
                
            </ul>
                
        </div>

    </div>
</template>

<script>
import { reactive, toRefs, onMounted, onUnmounted, computed,watch ,nextTick} from "vue";
import Swiper from "swiper"; // 引入库
import "swiper/css/swiper.css"; // 引入样式文件，注意5和6版本的样式文件不一致
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { getStorage, setStorage } from "@/js/common";
import {backUrl} from "@/js/util"
import * as echarts from 'echarts';
import {get_digitalTrainingPageList,get_select_stageList,get_digitalTrainingDataInfo,F_Get_shufflingPictureList} from "@/js/homeListApi.js"
import {baseUrl} from '@/js/util.js'
import { ElMessage } from 'element-plus';
export default {
    setup () {
        const router = useRouter();
        const store = useStore();
        const state = reactive({
            bList: [
                // {
                //   pictureUrl:require('@/assets/img/index/bannerlunbo.png')
                // }
            ],
            baseUrl:baseUrl,
            userId:getStorage('userId'),
            searchValue:'',
            screenItemIndex:-1,
            screenList:[
                // {
                //     name:'全部',

                // },{
                //     name:'智慧设计',

                // },{
                //     name:'智能制造',

                // },{
                //     name:'智慧施工',

                // },{
                //     name:'智慧运维',

                // },
            ],
            teachingCoursesList:[
                // {
                //     imgUrl:require('@/assets/img/courseTechingimg/jiegoulixueshixun.jpg'),
                //     title:'结构力学实训',
                //     update:'2022-10-29',
                //     author:'合肥工业大学',
                //     according:'智能建造',
                //     industry:'建筑',
                //     numberLearners:'2000'
                // }
                // ,{
                //     imgUrl:require('@/assets/img/courseTechingimg/zhuangpeishishixun.jpg'),
                //     title:'结构监测系统',
                //     update:'2022-10-29',
                //     author:'合肥工业大学',
                //     according:'智能建造',
                //     industry:'建筑',
                //     numberLearners:'2000'
                // },
            ],
            currentPage4: 2,
            pageIndex:1,
            pageSize:10,
            stageId:'',//阶段id
            num:2,
            ifautoplay:false,
            ifloop:false,
        })
        const methods = {
            // //    环图一
            // getEchartsOne() {
            //     var chartDom = document.getElementById('main1');
            //     var myChart = echarts.init(chartDom);
            //     var option;

            //     option = {
            //         tooltip: {
            //             trigger: 'item'
            //         },
            //         title: {
            //             text: '设计实训课程分析',
            //             padding: 20,
            //             textStyle: {
            //                 color: ''
            //             }
            //         },
            //         legend: {
            //             bottom: '5',
            //             left: 'center'
            //         },
            //         series: [
            //             {
            //                 name: 'Access From',
            //                 type: 'pie',
            //                 radius: ['30%', '40%'],
            //                 center: ['50%', '50%'],
            //                 avoidLabelOverlap: false,
            //                 label: {
            //                     show: true,

            //                 },

            //                 labelLine: {
            //                     show: true
            //                 },
            //                 data: [
            //                     { value: 1048, name: '建筑识图与构造' },
            //                     { value: 735, name: '静力力学' },
            //                     { value: 580, name: '工程测量' },
            //                     { value: 484, name: '建筑CAD' },
            //                     { value: 300, name: '钢结构与施工' },
            //                 ]
            //             }
            //         ]
            //     };

            //     option && myChart.setOption(option);
            // },
            // //    环图二
            // getEchartsTwo() {
            //     var chartDom = document.getElementById('main2');
            //     var myChart = echarts.init(chartDom);
            //     var option;

            //     option = {
            //         tooltip: {
            //             trigger: 'item'
            //         },
            //         title: {
            //             text: '生产实训课程分析',
            //             padding: 20,
            //             textStyle: {
            //                 color: ''
            //             }
            //         },
            //         legend: {
            //             bottom: '5',
            //             left: 'center'
            //         },
            //         series: [
            //             {
            //                 name: 'Access From',
            //                 type: 'pie',
            //                 radius: ['30%', '40%'],
            //                 center: ['50%', '50%'],
            //                 avoidLabelOverlap: false,
            //                 label: {
            //                     show: true,

            //                 },

            //                 labelLine: {
            //                     show: true
            //                 },
            //                 data: [
            //                     { value: 1048, name: '智能建造技术' },
            //                     { value: 735, name: 'BIM技术应用' },
            //                     { value: 580, name: '智能建造概论' },
            //                     { value: 484, name: '施工组织设计' },
            //                 ]
            //             }
            //         ]
            //     };

            //     option && myChart.setOption(option);
            // },
            // //    环图三
            // getEchartsThree() {
            //     var chartDom = document.getElementById('main3');
            //     var myChart = echarts.init(chartDom);
            //     var option;

            //     option = {
            //         tooltip: {
            //             trigger: 'item'
            //         },
            //         title: {
            //             text: '施工实训课程分析',
            //             padding: 20,
            //             textStyle: {
            //                 color: ''
            //             }
            //         },
            //         legend: {
            //             bottom: '5',
            //             left: 'center'
            //         },
            //         series: [
            //             {
            //                 name: 'Access From',
            //                 type: 'pie',
            //                 radius: ['30%', '40%'],
            //                 center: ['50%', '50%'],
            //                 avoidLabelOverlap: false,
            //                 label: {
            //                     show: true,

            //                 },

            //                 labelLine: {
            //                     show: true
            //                 },
            //                 data: [
            //                     { value: 1048, name: '混凝土结构与施工' },
            //                     { value: 735, name: '建筑装修工程施工' },
            //                     { value: 580, name: '建筑工程计量计价' },
            //                 ]
            //             }
            //         ]
            //     };

            //     option && myChart.setOption(option);
            // },
            // //    环图四
            // getEchartsFour() {
            //     var chartDom = document.getElementById('main4');
            //     var myChart = echarts.init(chartDom);
            //     var option;

            //     option = {
            //         tooltip: {
            //             trigger: 'item'
            //         },
            //         title: {
            //             text: '运维实训课程分析',
            //             padding: 20,
            //             textStyle: {
            //                 color: ''
            //             }
            //         },
            //         legend: {
            //             bottom: '5',
            //             left: 'center'
            //         },
            //         series: [
            //             {
            //                 name: 'Access From',
            //                 type: 'pie',
            //                 radius: ['30%', '40%'],
            //                 center: ['50%', '50%'],
            //                 avoidLabelOverlap: false,
            //                 label: {
            //                     show: true,

            //                 },

            //                 labelLine: {
            //                     show: true
            //                 },
            //                 data: [
            //                     { value: 1048, name: '大数据技术与智能建造平台应用' },
            //                     { value: 735, name: '建筑信息模型优化与虚拟施工' },
            //                 ]
            //             }
            //         ]
            //     };

            //     option && myChart.setOption(option);
            // },
            //获取课程实训分页数据
            getTrainingList(){
                let datas = {
                    stageId:state.stageId, //阶段id
                    dtName:state.searchValue,//搜索名称
                    pageIndex:state.pageIndex,
                    pageSize:state.pageSize,
                }
                get_digitalTrainingPageList(datas).then((res)=>{
                    if (res.data.code == 1) {
                        console.log(res.data.data.list,'课程实训分页数据');
                        state.teachingCoursesList = res.data.data.list
                    }else{
                        ElMessage.error(res.data.message)
                    }
                })
            },
            //获取阶段数据
            getscreenList(){
                get_select_stageList().then((res)=>{
                    if (res.data.code == 1) {
                        state.screenList = res.data.data
                        console.log(res.data.data,'阶段数据');
                    }
                })
            },
            //点击获取详情数据并进入详情
            rowClick(Id) {
              setStorage('digitalTrainingId', Id);
              router.push('/home/systemDetails')
            },

            //1、首页轮播 2、课程实训轮播 3、考评中心轮播
            // 获取轮播图
            async getRotationImg() {
                let res = await F_Get_shufflingPictureList({homeBasicType:2})
                if (res.data.code == 1) {
                    state.bList = res.data.data;
                    console.log(state.bList);
                    if (state.bList.length > 1) {
              state.ifautoplay = true
              state.ifloop = true
            } else if (state.bList.length <= 1) {
              state.ifautoplay = false
              state.ifloop = false
            }
                    nextTick(() => { initSwiper(); })

                }
            },

            //下拉加载更多
            load(){
                let datas = {
                    pageIndex:state.num++,
                    pageSize:state.pageSize,
                    stageId:state.stageId, //阶段id
                    dtName:state.searchValue,//搜索名称
                }
                get_digitalTrainingPageList(datas).then((res)=>{
                    if (res.data.code == 1) {
                        res.data.data.list.forEach(item => {
                            if (item) {
                                state.teachingCoursesList.push(item)
                            }
                        });
                        // state.count += res.data.data.totalCount.Value
                        console.log(state.teachingCoursesList,"课程实训分页数据222");
                    }else{
                        ElMessage.error(res.data.message)
                    }
                })
            },

            GObannerItem(item) {
              if (item.link) {
                  window.open(item.link)
              }
            },
            //点击搜索
            searchClick(){
                methods.getTrainingList();
            },
            //点击全部
            quanbu(){
                state.screenItemIndex = -1;
                state.stageId = '';
                methods.getTrainingList();
            },

            screenName(index,Id) {
                state.screenItemIndex = index;
                state.stageId = Id;
                methods.getTrainingList();

            },
           
        }
        const initSwiper = () => {
            new Swiper(".swiper-container", {
                slidesPerView: 'auto',
                spaceBetween: 30,
                speed: 3000,
                loop: state.ifloop,
                autoplay: state.ifautoplay, //自动切换

                autoplay: {
                    delay: 3000, //自动切换的时间间隔，单位ms
                    disableOnInteraction: false //用户操作swiper之后，是否禁止autoplay
                },
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                },
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
            });

        };
        onMounted(() => {
            // methods.getEchartsOne();
            // methods.getEchartsTwo();
            // methods.getEchartsThree();
            // methods.getEchartsFour();
            methods.getRotationImg();
            methods.getTrainingList();//获取课程实训分页数据
            methods.getscreenList();//获取阶段数据

        })
        return {
      ...toRefs(state),
      ...methods,
    };
    }
}
</script>

<style  lang="scss" scoped>
#courseTeaching{
    height: 100%;
    position: relative;
    background: #F7F8FA;
    .i-wrap {
        width: 66.5%;
        height: 100%;
        margin: 0 auto;
        :deep .el-input__inner {
            border-radius: 50px !important;
            border: none;
        }
        .banner-c {
            width: 100%;
            height: 110px;
            margin-top: 10px;
            //margin-bottom: 20px;
          .swiper-container {
            width: 100%;
            height: 100%;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            .bltext {
              position: absolute;
              bottom: 0;
              background: #00000080;
              width: 100%;
              font-size: 14px;
              //padding: 10px;
              color: #fff;
            }
          }
        }
      }
      :deep {
        .swiper-pagination-bullets {
          // bottom: 8px;
          // left: auto;
          // width: auto;
          // right: 30px;
        }
        .swiper-pagination-bullet {
          width: 8px;
          height: 8px;
          border-radius: 8px;
          box-sizing: border-box;
          border: 1px solid #fff;
          opacity: 1;
          background: transparent;
          margin: 0 10px;
          &.swiper-pagination-bullet-active {
            width: 25px;
            height: 8px;
            border-radius: 8px;
            box-sizing: border-box;
            border: 1px solid #fff;
            opacity: 1;
            background: #fff;
            margin: 0 10px;
          }
        }
      }
      .dList {
        // padding: 30px 0 0 0;
        width: 100%;
        margin: 0 auto;
         .dtoll{
            width:78px;
          }
            .oneLine2 {
           width: 1200px;
          margin: 0 auto;
          display: flex;
          // justify-content: space-between;
          .doll {
            width:980px;
            height: 305px;
            padding: 20px 0;
            box-sizing: border-box;
            background: #fff;
            overflow: hidden;
            .d-title {
              display: flex;
              justify-content: space-between;
              .dttext {
                font-size: 16px;
                font-weight: bold;
                color: #0200F9;
              }
              .mb {
                font-size: 12px;
                color: #666666;
                cursor: pointer;
              }
              margin: 0 0 10px;
            }
            .pl-c {
              display: flex;
              .pco {
                // width: 20%;
                // margin: 0 5% 0 0;
                &:last-child {
                  margin: 0;
                }
                &:nth-child(4){
                  // display: none;
                }
                .img-c {
                  // height: 187px;
                  // box-sizing: border-box;
                  // border: 1px solid #eee;
                          width: 224px;
                    height: 168px;
                    background: #ffffff;
                    border: 1px solid #e2e2e2;
                    margin-right: 21px;
                    box-sizing: border-box;
                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                  }
                }
                .pcotext {
                  color: #333333;
                  font-size: 14px;
                  padding: 15px 0 10px;
                  font-weight: bold;
                  width: 210px;
                }
              }
               .pco:hover{
                cursor:pointer;
                /* transform属性 */
                transform:translate(0,-10px);
                transition-duration: 500ms;
                .pcotext {
                  color: #0200F9;
                  font-size: 14px;
                  padding: 15px 0 10px;
                  font-weight: bold;
                  width: 210px;
                }
              }
            }
          }
         
          .dolr {
            height: 235px;
            width: 220px;
            padding: 20px;
            box-sizing: border-box;
            background: #fff;
            .dolr-titil {
              font-size: 16px;
              font-weight:bold;
              color: #0200F9;
              margin: 0 0 4px;
            }
            .dolr-tlist {
              .dtoneline {
                display: flex;
                justify-content: space-between;
                color: #333333;
                font-size: 14px;
                line-height: 32px;
              }
            }
          }
        }
        .oneLine {
          width: 1200px;
          margin:   0 auto;
          display: flex;
          // justify-content: space-between;
          .doll {
            width:980px;
            height: 305px;
            padding: 20px 0;
            box-sizing: border-box;
            background: #EBEBFF;
            overflow: hidden;
            .d-title {
              display: flex;
              justify-content: space-between;
              .dttext {
                font-size: 16px;
                font-weight: bold;
                color: #0200F9
              }
              .mb {
                font-size: 12px;
                color: #666666;
                cursor: pointer;
              }
              margin: 0 0 10px;
            }
            .pl-c {
              display: flex;
              .pco {
                // width: 20%;
                // margin: 0 5% 0 0;
                &:last-child {
                  margin: 0;
                }
            
                .img-c {
                  // height: 187px;
                  // box-sizing: border-box;
                  // border: 1px solid #eee;
                                 width: 224px;
                    height: 168px;
                    background: #ffffff;
                    border: 1px solid #e2e2e2;
                    margin-right: 21px;
                    box-sizing: border-box;
                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                  }
                }
                .pcotext {
                  color: #333333;
                  font-size: 14px;
                  padding: 15px 0 10px;
                  font-weight: bold;
                  width: 210px;
                }
              }
              .pco:hover{
                cursor:pointer;
                /* transform属性 */
                transform:translate(0,-10px);
                transition-duration: 500ms;
                .pcotext {
                  color: #0200F9;
                  font-size: 14px;
                  padding: 15px 0 10px;
                  font-weight: bold;
                  width: 210px;
                }
              }
            }
          }
         
          .dolr {
            height: 235px;
            width: 220px;
            padding: 20px;
            box-sizing: border-box;
            background: #EBEBFF;
            .dolr-titil {
              font-size: 16px;
              font-weight: bold;
              color: #0200F9;
              margin: 0 0 4px;
            }
            .dolr-tlist {
              .dtoneline {
                display: flex;
                justify-content: space-between;
                color: #333333;
                font-size: 14px;
                line-height: 32px;
              }
            }
          }
        }
      }
    .inputIcon{
        display: flex;
        justify-content: center;
        position: relative;

    }
    .inputIcon img{
        position: absolute;
        top: 25px;
        right: 10px;
        width: 30px;
        height: 30px;
        object-fit: contain;
        cursor: pointer;
    }
    .screen{
        width: 100%;
        background: #fff;
        margin-top: 10px;
        border-radius: 5px;
        .screenClass{
            float: left;
            font-size: 14px;
            padding: 10px 20px 10px 20px;
            border-radius: 8px;
            margin: 5px 0 5px 20px;
            cursor: pointer;
        }
        .active{
            background: #D3EDFC;
            color: #76C8F6;
        }
    }
    .ulList::-webkit-scrollbar {
        width: 4px;
        height: 0;
        background-color: transparent;
    }
    .ulList::-webkit-scrollbar-thumb {
        border-radius: 2px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #ffffff;
    }
    .ulList::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 1px transparent;
        border-radius: 10px;
        background-color: transparent;
    }
    .dataList{
        width: 100%;
        // height: 800px;
        // background: #fff;
        //margin-top: 10px;
    }
    .dataListRow{
        width: 100%;
        height: 180px;
        background: #fff;
        //margin-top: 10px;
        border-radius: 5px;
        cursor: pointer;
        display: flex;
        
    }
    .dataListRow:hover{
        box-shadow: 0px 0px 21px 0px rgba(0,0,0,0.06);
    }
    .el-pagination{
        background: none
    }
}
@media screen and (min-width: 1600px) {
    #index {
      .i-wrap {
        // width: 1400px;
        .banner-c {
          height: 520px;
          //margin-bottom: 10px;
        }
      }
      .dList {
        .dtoll{
          width: 78px;
        }
        .oneLine,.oneLine2  {
          width: 1400px;
          .doll {
            height: 305px;
                width: 1200px;
            overflow: hidden;
            .pl-c {
          
              .pco {
                    &:nth-child(4){
                display: inline-block;
              }
                .img-c {
                  margin-right: 18px;
                }
              }
               .pco:hover{
              cursor:pointer;
              /* transform属性 */
              transform:translate(0,-10px);
              transition-duration: 300ms;
              .pcotext {
                color: #0200F9;
                font-size: 14px;
                padding: 15px 0 10px;
                font-weight: bold;
                width: 210px;
              }
            }
            }
          }
          .dolr {
            height: 274px;
            width: 200px;
            padding: 20px 0px 20px 18px;
            .dolr-titil {
              margin: 0 0 4.5px;
            }
            .dolr-tlist {
              .dtoneline {
                line-height: 32px;
              }
            }
          }
        }
      }
    }
  }
</style>